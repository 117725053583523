import React from "react";
import {Button, Typography} from "@material-ui/core";
import {Link} from 'react-router-dom';
import {connect} from "react-redux";

class Header extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {role} = this.props;
        return (
            <div>
                <div
                    className='shadow bg-white py-8 px-16 flex flex-row fixed w-full top-0 z-9999 align-middle items-center h-64'>
                    <div className='flex flex-1 flex-row'>
                        <Link to={'/'}>
                            <img src='assets/logo-2.png' className='h-32 mt-8 block'/>
                        </Link>
                        <div className='mx-auto block'>
                            <Typography className='text-2xl pl-5 font-bold text-center' color={"primary"}>
                                Hatch<span className='font-light'>IDS</span>
                            </Typography>
                            <Typography className='pl-5 -mt-2' color={"primary"}>
                                A Fully Automated IDS (SB08) Form Generator
                            </Typography>

                        </div>

                    </div>
                    <div className='align-middle'>
                        {
                            (role === null || role === undefined || role.length === 0) ? (
                                <div>
                                    <Button
                                        className='rounded-none mr-3'
                                        color={"primary"} component={Link} to={'/login'} variant={"outlined"}
                                        size={"small"}>Login</Button>
                                    <Button
                                        className='rounded-none'
                                        color={"secondary"} component={Link} to={'/signup'} variant={"outlined"}
                                        size={"small"}>Register</Button>
                                </div>
                            ) : (
                                <Button
                                    className='rounded-none'
                                    color={"secondary"} component={Link} to={'/home'} variant={"outlined"}
                                    size={"small"}>Go to console</Button>
                            )

                        }
                    </div>

                </div>
                <div className='pt-64' hidden={this.props.hideNavbar}>
                    <div className='h-64 flex flex-row items-center px-24 text-16 font-medium bg-blue-dark'>
                        <Link className='px-12 text-blue-light' to='/' style={{color:'#fff'}}>Home</Link>
                        <Link className='px-12' to="/what-is-hatch-ids" style={{color:'#fff'}}>What is HatchIDS?</Link>
                        <Link className='px-12' to="/about-us" style={{color:'#fff'}}>About Us</Link>
                        <Link className='px-12' to='/insights' style={{color:'#fff'}}>Insights</Link>
                        <Link className='px-12' to='/faqs' style={{color:'#fff'}}>FAQs</Link>
                        <Link className='px-12' to='/contact-us' style={{color:'#fff'}}>Contact Us</Link>
                    </div>

                </div>
            </div>
        )
    }

}

function mapStateToProps({auth}) {
    return {
        login: auth.login,
        user: auth.user,
        role: auth.user.role
    }
}

export default connect(mapStateToProps)(Header);
